<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="files" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 v-if="directoryName === ''" class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> Files</h5>
              <h5 v-else-if="parentDirectory.indexOf('/') === -1" class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> <router-link :to="`/${project.group.path}/${project.path}/files`" class="no-link fs-16 text-muted">Files</router-link> <span class="fs-16 mx-1 text-muted">/</span> {{ directoryName }}</h5>
              <h5 v-else class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> <router-link :to="`/${project.group.path}/${project.path}/files`" class="no-link fs-16 text-muted">Files</router-link> <span class="fs-16 mx-1 text-muted">/</span> <span class="fs-16 text-muted">...</span> <span class="fs-16 mx-1 text-muted">/</span> {{ directoryName }}</h5>
            </div>
            <div class="page-title-right">
              <label class="btn btn-primary btn-rounded mr-sm-2 mb-0"><i class="fal fa-cloud-upload mr-2"></i> Upload Files<upload :group="project.group.path" :project="project.path" :directory="directory" type="files" @callback="insertFile" /></label>
              <button type="button" class="btn btn-default btn-rounded mb-0 d-none d-sm-inline-flex" v-b-modal.foldermodal><i class="fal fa-folder-plus mr-2"></i>New Folder</button>
            </div>
          </div>

          <div class="widget-list">

            <div class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div v-if="directory !== '' || files.length !== 0">
                    <div class="row font-weight-bold">
                      <div class="col-11 col-sm-9 col-md-7">Name</div><div class="col-2 d-none d-sm-block text-right">Size</div><div class="col-2 d-none d-md-block text-right">Modified</div><div class="col-1"></div>
                    </div>
                    <div v-if="directory !== ''">
                      <hr>
                      <i class="fal fa-lg fa-folder-tree fa-fw text-muted opacity-08 mr-2"></i>
                      <router-link :to="`/${this.project.group.path}/${this.project.path}/files/${parentDirectory}`">Parent Directory</router-link>
                    </div>
                    <div v-for="f in directoryFiles" :key="f.id">
                      <hr>
                      <div class="row">
                        <div class="col-11 col-sm-9 col-md-7 text-truncate">
                          <i class="fal fa-lg fa-fw text-muted opacity-08 mr-2" :class="fileIcon(f.mimetype)"></i>
                          <router-link v-if="f.mimetype == 'directory'" :to="f.url">{{ decodeURIComponent(f.filename) }}</router-link>
                          <a v-else :href="`${$api.defaults.baseURL}${f.url}`">{{ decodeURIComponent(f.filename) }}</a>
                        </div>
                        <div class="col-2 d-none d-sm-block text-muted text-right"><span v-if="f.mimetype !== 'directory'">{{ f.size | formatSize }}</span></div>
                        <div class="col-2 d-none d-md-block text-muted text-right"><span v-if="f.mimetype !== 'directory'">{{ f.created_at | formatDate }}</span></div>
                        <div class="col-1 text-right"><button type="button" class="btn btn-link py-0 px-2" title="Delete" @click="deleteFile(f)"><i class="fal fa-times fa-lg fa-fw text-primary opacity-08"></i></button></div>
                      </div>
                    </div>
                  </div>
                  <div v-if="directory === '' && files.length === 0" class="text-center py-4">
                    <h4 class="my-0"><p><i class="fal fa-code fa-5x text-stroke-5 text-icon-gray"></i></p><p>No files found for this project</p></h4>
                    <label class="btn btn-primary btn-rounded"><i class="fal fa-cloud-upload mr-2"></i>Upload Files<upload :group="project.group.path" :project="project.path" :directory="directory" type="files" @callback="insertFile" /></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-muted small">Total {{ totalSpaceUsed | formatSize }} used of 2 GB; 250 MB file size limit</div>

          </div>

          <b-modal id="foldermodal" title="New Folder" centered @show="upload.directory = ''">
            <div class="form-group">
              <label class="col-form-label">Name</label>
              <input type="text" class="form-control" v-model="upload.directory" maxlength="128" @keyup.enter="createFolder()">
            </div>
            <template v-slot:modal-footer>
              <button type="button" class="btn btn-primary btn-rounded mr-auto" @click="createFolder()">Create Folder</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import Upload from '@/components/Upload.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);

export default {
  data() {
    return {
      project: {},
      directory: '',
      files: [],
      upload: {
        directory: '',
      },
    };
  },
  filters: {
    formatSize(v) {
      let unit = ' B';
      let b = v;
      if (v >= 1000) { unit = ' KB'; b /= 1000; }
      if (v >= 1000 * 1000) { unit = ' MB'; b /= 1000; }
      if (v >= 1000 * 1000 * 1000) { unit = ' GB'; b /= 1000; }
      if (unit !== ' B') {
        b = Math.round(b);
      }
      return b + unit;
    },
    formatDate(v) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const d = new Date(v);
      return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    },
  },
  computed: {
    directoryFiles() {
      const list = [];
      for (let i = 0; i < this.files.length; i += 1) {
        if (this.files[i].path.startsWith(this.directory)) {
          const p = this.files[i].path.substring(this.directory.length);
          if (p.indexOf('/') === -1) {
            list.push(this.files[i]);
          }
        }
      }
      return list;
    },
    parentDirectory() {
      if (this.directory === '') {
        return '';
      }
      const ary = this.directory.split('/');
      if (ary.length <= 2) {
        return '';
      }
      ary.pop();
      ary.pop();
      return `${ary.join('/')}/`;
    },
    directoryName() {
      if (this.directory !== '') {
        const ary = this.directory.split('/');
        if (ary.length > 0) {
          ary.pop();
          return decodeURIComponent(ary.pop());
        }
      }
      return '';
    },
    totalSpaceUsed() {
      let total = 0;
      for (let i = 0; i < this.files.length; i += 1) {
        total += this.files[i].size;
      }
      return total;
    },
  },
  methods: {
    fileIcon(mimetype) {
      let icon = 'fa-file';
      if (mimetype === 'directory') {
        icon = 'fa-folder';
      } else if (mimetype === 'application/msword' || mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || mimetype === 'application/vnd.oasis.opendocument.text' || mimetype === 'application/rtf') {
        icon = 'fa-file-word';
      } else if (mimetype === 'application/vnd.ms-powerpoint' || mimetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || mimetype === 'application/vnd.oasis.opendocument.presentation') {
        icon = 'fa-file-chart-pie';
      } else if (mimetype === 'application/vnd.ms-excel' || mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || mimetype === 'application/vnd.oasis.opendocument.spreadsheet') {
        icon = 'fa-file-spreadsheet';
      } else if (mimetype === 'application/pdf') {
        icon = 'fa-file-pdf';
      } else if (mimetype === 'application/x-freearc' || mimetype === 'application/x-bzip' || mimetype === 'application/x-bzip2' || mimetype === 'application/x-rar-compressed' || mimetype === 'application/x-tar' || mimetype === 'application/zip' || mimetype === 'application/x-7z-compressed') {
        icon = 'fa-file-archive';
      } else if (mimetype === 'text/csv') {
        icon = 'fa-file-csv';
      } else if (mimetype === 'text/css' || mimetype === 'text/html' || mimetype === 'application/java-archive' || mimetype === 'text/javascript' || mimetype === 'application/x-shellscript') {
        icon = 'fa-file-code';
      } else if (mimetype.startsWith('text/')) {
        icon = 'fa-file-alt';
      } else if (mimetype.startsWith('video/')) {
        icon = 'fa-file-video';
      } else if (mimetype.startsWith('image/')) {
        icon = 'fa-file-image';
      } else if (mimetype.startsWith('audio/')) {
        icon = 'fa-file-audio';
      }
      return icon;
    },
    createFolder() {
      const form = new FormData();
      const formData = new Blob([], { type: 'directory' });
      form.set('file', formData, this.upload.directory);

      this.$api.post(`/projects/${this.project.group.path}%2F${this.project.path}/files/${encodeURIComponent(decodeURIComponent(this.directory) + this.upload.directory)}`, form, { headers: { 'content-type': 'multipart/form-data' } })
        .then((res) => {
          this.insertFile(res);
          this.$bvModal.hide('foldermodal');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    deleteFile(f) {
      this.$api.delete(`/projects/${this.project.group.path}%2F${this.project.path}/files/${f.id}/${f.filename}`)
        .then(() => {
          for (let i = 0; i < this.files.length; i += 1) {
            if (this.files[i].id === f.id) {
              this.files.splice(i, 1);
              break;
            }
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    insertFile(res) {
      // remove any existing file with same path
      for (let i = 0; i < this.files.length; i += 1) {
        if (this.files[i].path === res.data.path) {
          this.files.splice(i, 1);
          break;
        }
      }
      // add file
      this.files.push(res.data);
    },
  },
  async mounted() {
    try {
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      // check if files is enabled
      let f = false;
      const m = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`)).data;
      for (let i = 0; i < m.length; i += 1) {
        if (m[i].id === this.$auth.profile.id) {
          f = m[i].enable_files;
          break;
        }
      }
      if (!f && !this.$auth.profile.site_admin) {
        throw new Error(404);
      }
      this.files = (await this.$api.get(`/projects/${this.project.group.path}%2F${this.project.path}/files`)).data;
      if (this.$route.params.directory) {
        const d = encodeURIComponent(this.$route.params.directory).replace('%2F', '/');
        for (let i = 0; i < this.files.length; i += 1) {
          if (this.files[i].path === d) {
            this.directory = `${d}/`;
          }
        }
        if (this.directory === '') {
          this.$store.commit('error', { status: 404, message: 'File does not exist' });
          return;
        }
      }
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
    Upload,
  },
};
</script>
